import React, { useEffect } from 'react';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw';
import $ from "jquery";
import DateFormat from '@components/format-date/';
import ImgBox from '@components/img-box/img-box';
import { Logo } from '@components/icon/icon';
import { Container } from 'react-bootstrap';

/**
 * Assets
 */
import './styles/_index.scss'

/**
 * Components
 */

import Intro from '@components/intro/intro';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import SEO from "@components/seo";

function NewsIntro(props) {

  if (typeof window !== "undefined") {
    $(window).scroll(function () {
      var sticky = $('.sticky-header'),
        scroll = $(window).scrollTop();

      if (scroll >= 40) sticky.addClass('fixed');
      else sticky.removeClass('fixed');
    });
  }

  return (
    <> 
      <SEO title={props?.Title} description={'Read about ' + props?.Title + ' here and Subscribe to get all of our latest news and insights.'} />
      <div className="breadcrumbs-intro">
        <Breadcrumbs alias="blog" detailTitle={props?.Title} />
      </div> 
    </>
  )
}

export default NewsIntro
