import React from "react"
import { Row, Container, Col } from 'react-bootstrap';
import Layout from "../components/layout";
import { graphql } from 'gatsby'
import "aos/dist/aos.css";

import NewsBreadcrumbs from "@components/news-intro/news-breadcrumbs";
import NewsIntro from "@components/news-intro/news-intro";
import NewsWave from '@components/news-wave/news-wave';
import NewsContent from '@components/news-content/news-content';
import NewsOtherPost from "@components/news-other-post/news-other-post";
import ElfsightInstaWidgetModule from "../components/modules/ElfsightInstaWidgetModule/ElfsightInstaWidgetModule";
import Sticky from 'react-stickynode';

const NewsDetail = (props) => {
 const news = props?.data?.strapiNewsEvents;
 const GlobalConfig = props?.data.strapiGlobalConfig 
  return (
  <div className="news-detail">
    <Layout>
    <NewsBreadcrumbs {...news} />
      <Container>       
        <Row>
          <Col md={12}>
              <NewsIntro {...news} />
          </Col>
        </Row>
        <div className="news-detail-content">
          <Row>
            <Col md={12} xl={8} lg={8}>      
                <NewsContent {...news}/>
            </Col>
            <Col xl={4} lg={4} className="right-side-bar">
              <Sticky top={200} bottomBoundary='.bottomBoundary'>
                  <NewsWave {...news}/>
              </Sticky>
            </Col>
          </Row>
        </div>
        <div className="bottomBoundary"></div>       
      </Container>
      {<NewsOtherPost DetailURL = {news.URL} />}
      <ElfsightInstaWidgetModule ModuleData = {GlobalConfig?.Elfsight_Instagram_Widget?.Widget_Code} />
    </Layout>
  </div>
)
}

export default NewsDetail;


export const pageQuery = graphql`
query GetNewsDetails($slug: String!) {
  strapiNewsEvents(URL: {eq: $slug}) {
    Title
    URL
    Published_Date
    Content
    Banner_Image {
      publicURL
    }
    teams {
      Name
      Designation
      Phone
      Image {
        publicURL
      }
    }
  }
  strapiGlobalConfig {
      Get_In_Touch_Content
      Send_US_Form_Content
      Elfsight_Reviews {
        Badge_Widget_Code
        Carousel_Widget_Code
        Widget_Embed_Video_URL
        Percentage_value
        Total_reviews
        Widget_Video_Bg_Image {
          internal {
            description
          }
          childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }        
      Elfsight_Instagram_Widget {
        Widget_Code
      }
      GetStartedForm {
        Section_Title
        Section_Intro
        FormList
        FormContactData {
          Label
          Link
          Type
          Icon_Type
          Show_Icon
        }
      }
  }
}
`
